import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Account: () => import('../../components/account/Account.vue' /* webpackChunkName: "components/account" */).then(c => wrapFunctional(c.default || c)),
  AccountForgot: () => import('../../components/account/AccountForgot.vue' /* webpackChunkName: "components/account-forgot" */).then(c => wrapFunctional(c.default || c)),
  AccountForgotOld: () => import('../../components/account/AccountForgotOld.vue' /* webpackChunkName: "components/account-forgot-old" */).then(c => wrapFunctional(c.default || c)),
  AccountOffersOld: () => import('../../components/account/AccountOffersOld.vue' /* webpackChunkName: "components/account-offers-old" */).then(c => wrapFunctional(c.default || c)),
  AccountPasswordReset: () => import('../../components/account/AccountPasswordReset.vue' /* webpackChunkName: "components/account-password-reset" */).then(c => wrapFunctional(c.default || c)),
  AccountPayments: () => import('../../components/account/AccountPayments.vue' /* webpackChunkName: "components/account-payments" */).then(c => wrapFunctional(c.default || c)),
  AccountPreferences: () => import('../../components/account/AccountPreferences.vue' /* webpackChunkName: "components/account-preferences" */).then(c => wrapFunctional(c.default || c)),
  AccountProfile: () => import('../../components/account/AccountProfile.vue' /* webpackChunkName: "components/account-profile" */).then(c => wrapFunctional(c.default || c)),
  AccountRewards: () => import('../../components/account/AccountRewards.vue' /* webpackChunkName: "components/account-rewards" */).then(c => wrapFunctional(c.default || c)),
  AccountSavedGiftCards: () => import('../../components/account/AccountSavedGiftCards.vue' /* webpackChunkName: "components/account-saved-gift-cards" */).then(c => wrapFunctional(c.default || c)),
  AccountSignin: () => import('../../components/account/AccountSignin.vue' /* webpackChunkName: "components/account-signin" */).then(c => wrapFunctional(c.default || c)),
  AccountSigninOld: () => import('../../components/account/AccountSigninOld.vue' /* webpackChunkName: "components/account-signin-old" */).then(c => wrapFunctional(c.default || c)),
  AccountSignup: () => import('../../components/account/AccountSignup.vue' /* webpackChunkName: "components/account-signup" */).then(c => wrapFunctional(c.default || c)),
  OrderHistory: () => import('../../components/account/OrderHistory.vue' /* webpackChunkName: "components/order-history" */).then(c => wrapFunctional(c.default || c)),
  RemoveAccountModal: () => import('../../components/account/RemoveAccountModal.vue' /* webpackChunkName: "components/remove-account-modal" */).then(c => wrapFunctional(c.default || c)),
  RewardCard: () => import('../../components/account/RewardCard.vue' /* webpackChunkName: "components/reward-card" */).then(c => wrapFunctional(c.default || c)),
  Accordion: () => import('../../components/common/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c)),
  AppButton: () => import('../../components/common/AppButton.vue' /* webpackChunkName: "components/app-button" */).then(c => wrapFunctional(c.default || c)),
  AppLink: () => import('../../components/common/AppLink.vue' /* webpackChunkName: "components/app-link" */).then(c => wrapFunctional(c.default || c)),
  BackButton: () => import('../../components/common/BackButton.vue' /* webpackChunkName: "components/back-button" */).then(c => wrapFunctional(c.default || c)),
  CalloutSlider: () => import('../../components/common/CalloutSlider.vue' /* webpackChunkName: "components/callout-slider" */).then(c => wrapFunctional(c.default || c)),
  Card: () => import('../../components/common/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c)),
  CardSection: () => import('../../components/common/CardSection.vue' /* webpackChunkName: "components/card-section" */).then(c => wrapFunctional(c.default || c)),
  CardSliderSection: () => import('../../components/common/CardSliderSection.vue' /* webpackChunkName: "components/card-slider-section" */).then(c => wrapFunctional(c.default || c)),
  Cart: () => import('../../components/common/Cart.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c)),
  CartQuickAdds: () => import('../../components/common/CartQuickAdds.vue' /* webpackChunkName: "components/cart-quick-adds" */).then(c => wrapFunctional(c.default || c)),
  ColoredBackgroundImageTextSection: () => import('../../components/common/ColoredBackgroundImageTextSection.vue' /* webpackChunkName: "components/colored-background-image-text-section" */).then(c => wrapFunctional(c.default || c)),
  ContactUsOld: () => import('../../components/common/ContactUsOld.vue' /* webpackChunkName: "components/contact-us-old" */).then(c => wrapFunctional(c.default || c)),
  ContentSection: () => import('../../components/common/ContentSection.vue' /* webpackChunkName: "components/content-section" */).then(c => wrapFunctional(c.default || c)),
  CorporateContact: () => import('../../components/common/CorporateContact.vue' /* webpackChunkName: "components/corporate-contact" */).then(c => wrapFunctional(c.default || c)),
  DefaultButton: () => import('../../components/common/DefaultButton.vue' /* webpackChunkName: "components/default-button" */).then(c => wrapFunctional(c.default || c)),
  DownloadApp: () => import('../../components/common/DownloadApp.vue' /* webpackChunkName: "components/download-app" */).then(c => wrapFunctional(c.default || c)),
  ErrorPage: () => import('../../components/common/ErrorPage.vue' /* webpackChunkName: "components/error-page" */).then(c => wrapFunctional(c.default || c)),
  ExpandingSection: () => import('../../components/common/ExpandingSection.vue' /* webpackChunkName: "components/expanding-section" */).then(c => wrapFunctional(c.default || c)),
  FeaturedContent: () => import('../../components/common/FeaturedContent.vue' /* webpackChunkName: "components/featured-content" */).then(c => wrapFunctional(c.default || c)),
  FranchiseeLogin: () => import('../../components/common/FranchiseeLogin.vue' /* webpackChunkName: "components/franchisee-login" */).then(c => wrapFunctional(c.default || c)),
  GiftCards: () => import('../../components/common/GiftCards.vue' /* webpackChunkName: "components/gift-cards" */).then(c => wrapFunctional(c.default || c)),
  HeadingDescription: () => import('../../components/common/HeadingDescription.vue' /* webpackChunkName: "components/heading-description" */).then(c => wrapFunctional(c.default || c)),
  Hero: () => import('../../components/common/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c)),
  HeroImage: () => import('../../components/common/HeroImage.vue' /* webpackChunkName: "components/hero-image" */).then(c => wrapFunctional(c.default || c)),
  HeroSlide: () => import('../../components/common/HeroSlide.vue' /* webpackChunkName: "components/hero-slide" */).then(c => wrapFunctional(c.default || c)),
  JoinWaitlist: () => import('../../components/common/JoinWaitlist.vue' /* webpackChunkName: "components/join-waitlist" */).then(c => wrapFunctional(c.default || c)),
  LimitedTimeOffers: () => import('../../components/common/LimitedTimeOffers.vue' /* webpackChunkName: "components/limited-time-offers" */).then(c => wrapFunctional(c.default || c)),
  LimitedTimeOnly: () => import('../../components/common/LimitedTimeOnly.vue' /* webpackChunkName: "components/limited-time-only" */).then(c => wrapFunctional(c.default || c)),
  Loader: () => import('../../components/common/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c)),
  LocationHandoff: () => import('../../components/common/LocationHandoff.vue' /* webpackChunkName: "components/location-handoff" */).then(c => wrapFunctional(c.default || c)),
  LocationSearch: () => import('../../components/common/LocationSearch.vue' /* webpackChunkName: "components/location-search" */).then(c => wrapFunctional(c.default || c)),
  Lottie: () => import('../../components/common/Lottie.vue' /* webpackChunkName: "components/lottie" */).then(c => wrapFunctional(c.default || c)),
  Map: () => import('../../components/common/Map.vue' /* webpackChunkName: "components/map" */).then(c => wrapFunctional(c.default || c)),
  Markdown: () => import('../../components/common/Markdown.vue' /* webpackChunkName: "components/markdown" */).then(c => wrapFunctional(c.default || c)),
  Media: () => import('../../components/common/Media.vue' /* webpackChunkName: "components/media" */).then(c => wrapFunctional(c.default || c)),
  MenuLink: () => import('../../components/common/MenuLink.vue' /* webpackChunkName: "components/menu-link" */).then(c => wrapFunctional(c.default || c)),
  MenuSlider: () => import('../../components/common/MenuSlider.vue' /* webpackChunkName: "components/menu-slider" */).then(c => wrapFunctional(c.default || c)),
  Mission: () => import('../../components/common/Mission.vue' /* webpackChunkName: "components/mission" */).then(c => wrapFunctional(c.default || c)),
  NavLink: () => import('../../components/common/NavLink.vue' /* webpackChunkName: "components/nav-link" */).then(c => wrapFunctional(c.default || c)),
  OrderTime: () => import('../../components/common/OrderTime.vue' /* webpackChunkName: "components/order-time" */).then(c => wrapFunctional(c.default || c)),
  Paragraph: () => import('../../components/common/Paragraph.vue' /* webpackChunkName: "components/paragraph" */).then(c => wrapFunctional(c.default || c)),
  Quote: () => import('../../components/common/Quote.vue' /* webpackChunkName: "components/quote" */).then(c => wrapFunctional(c.default || c)),
  RadarMap: () => import('../../components/common/RadarMap.vue' /* webpackChunkName: "components/radar-map" */).then(c => wrapFunctional(c.default || c)),
  SarahDarling: () => import('../../components/common/SarahDarling.vue' /* webpackChunkName: "components/sarah-darling" */).then(c => wrapFunctional(c.default || c)),
  ScrollNav: () => import('../../components/common/ScrollNav.vue' /* webpackChunkName: "components/scroll-nav" */).then(c => wrapFunctional(c.default || c)),
  SmartLink: () => import('../../components/common/SmartLink.vue' /* webpackChunkName: "components/smart-link" */).then(c => wrapFunctional(c.default || c)),
  StaticHero: () => import('../../components/common/StaticHero.vue' /* webpackChunkName: "components/static-hero" */).then(c => wrapFunctional(c.default || c)),
  StickyNav: () => import('../../components/common/StickyNav.vue' /* webpackChunkName: "components/sticky-nav" */).then(c => wrapFunctional(c.default || c)),
  StorySlider: () => import('../../components/common/StorySlider.vue' /* webpackChunkName: "components/story-slider" */).then(c => wrapFunctional(c.default || c)),
  SummerSpecialsCards: () => import('../../components/common/SummerSpecialsCards.vue' /* webpackChunkName: "components/summer-specials-cards" */).then(c => wrapFunctional(c.default || c)),
  SummerSpecialsCombos: () => import('../../components/common/SummerSpecialsCombos.vue' /* webpackChunkName: "components/summer-specials-combos" */).then(c => wrapFunctional(c.default || c)),
  SummerSpecialsHero: () => import('../../components/common/SummerSpecialsHero.vue' /* webpackChunkName: "components/summer-specials-hero" */).then(c => wrapFunctional(c.default || c)),
  Teaser: () => import('../../components/common/Teaser.vue' /* webpackChunkName: "components/teaser" */).then(c => wrapFunctional(c.default || c)),
  TextPage: () => import('../../components/common/TextPage.vue' /* webpackChunkName: "components/text-page" */).then(c => wrapFunctional(c.default || c)),
  TextSection: () => import('../../components/common/TextSection.vue' /* webpackChunkName: "components/text-section" */).then(c => wrapFunctional(c.default || c)),
  ConditionalCheckbox: () => import('../../components/forms/ConditionalCheckbox.vue' /* webpackChunkName: "components/conditional-checkbox" */).then(c => wrapFunctional(c.default || c)),
  ConditionalSelect: () => import('../../components/forms/ConditionalSelect.vue' /* webpackChunkName: "components/conditional-select" */).then(c => wrapFunctional(c.default || c)),
  DynamicForm: () => import('../../components/forms/DynamicForm.vue' /* webpackChunkName: "components/dynamic-form" */).then(c => wrapFunctional(c.default || c)),
  LocatorField: () => import('../../components/forms/LocatorField.vue' /* webpackChunkName: "components/locator-field" */).then(c => wrapFunctional(c.default || c)),
  Amex: () => import('../../components/icons/Amex.vue' /* webpackChunkName: "components/amex" */).then(c => wrapFunctional(c.default || c)),
  Arrow: () => import('../../components/icons/Arrow.vue' /* webpackChunkName: "components/arrow" */).then(c => wrapFunctional(c.default || c)),
  Discover: () => import('../../components/icons/Discover.vue' /* webpackChunkName: "components/discover" */).then(c => wrapFunctional(c.default || c)),
  IconArrowDownRed: () => import('../../components/icons/IconArrowDownRed.vue' /* webpackChunkName: "components/icon-arrow-down-red" */).then(c => wrapFunctional(c.default || c)),
  IconArrowRight: () => import('../../components/icons/IconArrowRight.vue' /* webpackChunkName: "components/icon-arrow-right" */).then(c => wrapFunctional(c.default || c)),
  IconBack: () => import('../../components/icons/IconBack.vue' /* webpackChunkName: "components/icon-back" */).then(c => wrapFunctional(c.default || c)),
  IconBag: () => import('../../components/icons/IconBag.vue' /* webpackChunkName: "components/icon-bag" */).then(c => wrapFunctional(c.default || c)),
  IconCart: () => import('../../components/icons/IconCart.vue' /* webpackChunkName: "components/icon-cart" */).then(c => wrapFunctional(c.default || c)),
  IconClock: () => import('../../components/icons/IconClock.vue' /* webpackChunkName: "components/icon-clock" */).then(c => wrapFunctional(c.default || c)),
  IconClose: () => import('../../components/icons/IconClose.vue' /* webpackChunkName: "components/icon-close" */).then(c => wrapFunctional(c.default || c)),
  IconCloseWhite: () => import('../../components/icons/IconCloseWhite.vue' /* webpackChunkName: "components/icon-close-white" */).then(c => wrapFunctional(c.default || c)),
  IconCurbside: () => import('../../components/icons/IconCurbside.vue' /* webpackChunkName: "components/icon-curbside" */).then(c => wrapFunctional(c.default || c)),
  IconCurbsideWhite: () => import('../../components/icons/IconCurbsideWhite.vue' /* webpackChunkName: "components/icon-curbside-white" */).then(c => wrapFunctional(c.default || c)),
  IconDelivery: () => import('../../components/icons/IconDelivery.vue' /* webpackChunkName: "components/icon-delivery" */).then(c => wrapFunctional(c.default || c)),
  IconDeliveryWhite: () => import('../../components/icons/IconDeliveryWhite.vue' /* webpackChunkName: "components/icon-delivery-white" */).then(c => wrapFunctional(c.default || c)),
  IconFacebook: () => import('../../components/icons/IconFacebook.vue' /* webpackChunkName: "components/icon-facebook" */).then(c => wrapFunctional(c.default || c)),
  IconInstagram: () => import('../../components/icons/IconInstagram.vue' /* webpackChunkName: "components/icon-instagram" */).then(c => wrapFunctional(c.default || c)),
  IconInstagramLg: () => import('../../components/icons/IconInstagramLg.vue' /* webpackChunkName: "components/icon-instagram-lg" */).then(c => wrapFunctional(c.default || c)),
  IconMarker: () => import('../../components/icons/IconMarker.vue' /* webpackChunkName: "components/icon-marker" */).then(c => wrapFunctional(c.default || c)),
  IconMarkerBlack: () => import('../../components/icons/IconMarkerBlack.vue' /* webpackChunkName: "components/icon-marker-black" */).then(c => wrapFunctional(c.default || c)),
  IconMarkerDarkGreen: () => import('../../components/icons/IconMarkerDarkGreen.vue' /* webpackChunkName: "components/icon-marker-dark-green" */).then(c => wrapFunctional(c.default || c)),
  IconMarkerGreen: () => import('../../components/icons/IconMarkerGreen.vue' /* webpackChunkName: "components/icon-marker-green" */).then(c => wrapFunctional(c.default || c)),
  IconMarkerLarge: () => import('../../components/icons/IconMarkerLarge.vue' /* webpackChunkName: "components/icon-marker-large" */).then(c => wrapFunctional(c.default || c)),
  IconMarkerRed: () => import('../../components/icons/IconMarkerRed.vue' /* webpackChunkName: "components/icon-marker-red" */).then(c => wrapFunctional(c.default || c)),
  IconOrder: () => import('../../components/icons/IconOrder.vue' /* webpackChunkName: "components/icon-order" */).then(c => wrapFunctional(c.default || c)),
  IconPasswordEye: () => import('../../components/icons/IconPasswordEye.vue' /* webpackChunkName: "components/icon-password-eye" */).then(c => wrapFunctional(c.default || c)),
  IconPickup: () => import('../../components/icons/IconPickup.vue' /* webpackChunkName: "components/icon-pickup" */).then(c => wrapFunctional(c.default || c)),
  IconPickupWhite: () => import('../../components/icons/IconPickupWhite.vue' /* webpackChunkName: "components/icon-pickup-white" */).then(c => wrapFunctional(c.default || c)),
  IconResultClock: () => import('../../components/icons/IconResultClock.vue' /* webpackChunkName: "components/icon-result-clock" */).then(c => wrapFunctional(c.default || c)),
  IconResultMarker: () => import('../../components/icons/IconResultMarker.vue' /* webpackChunkName: "components/icon-result-marker" */).then(c => wrapFunctional(c.default || c)),
  IconResultPhone: () => import('../../components/icons/IconResultPhone.vue' /* webpackChunkName: "components/icon-result-phone" */).then(c => wrapFunctional(c.default || c)),
  IconSelected: () => import('../../components/icons/IconSelected.vue' /* webpackChunkName: "components/icon-selected" */).then(c => wrapFunctional(c.default || c)),
  IconStore: () => import('../../components/icons/IconStore.vue' /* webpackChunkName: "components/icon-store" */).then(c => wrapFunctional(c.default || c)),
  IconTwitter: () => import('../../components/icons/IconTwitter.vue' /* webpackChunkName: "components/icon-twitter" */).then(c => wrapFunctional(c.default || c)),
  IconUser: () => import('../../components/icons/IconUser.vue' /* webpackChunkName: "components/icon-user" */).then(c => wrapFunctional(c.default || c)),
  IconUserGreen: () => import('../../components/icons/IconUserGreen.vue' /* webpackChunkName: "components/icon-user-green" */).then(c => wrapFunctional(c.default || c)),
  IconX: () => import('../../components/icons/IconX.vue' /* webpackChunkName: "components/icon-x" */).then(c => wrapFunctional(c.default || c)),
  IconYoutube: () => import('../../components/icons/IconYoutube.vue' /* webpackChunkName: "components/icon-youtube" */).then(c => wrapFunctional(c.default || c)),
  Mastercard: () => import('../../components/icons/Mastercard.vue' /* webpackChunkName: "components/mastercard" */).then(c => wrapFunctional(c.default || c)),
  RibbonIcon: () => import('../../components/icons/RibbonIcon.vue' /* webpackChunkName: "components/ribbon-icon" */).then(c => wrapFunctional(c.default || c)),
  Visa: () => import('../../components/icons/Visa.vue' /* webpackChunkName: "components/visa" */).then(c => wrapFunctional(c.default || c)),
  Banner: () => import('../../components/layout/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c)),
  BottomBanner: () => import('../../components/layout/BottomBanner.vue' /* webpackChunkName: "components/bottom-banner" */).then(c => wrapFunctional(c.default || c)),
  CartIcon: () => import('../../components/layout/CartIcon.vue' /* webpackChunkName: "components/cart-icon" */).then(c => wrapFunctional(c.default || c)),
  Column: () => import('../../components/layout/Column.vue' /* webpackChunkName: "components/column" */).then(c => wrapFunctional(c.default || c)),
  ErrorOverlay: () => import('../../components/layout/ErrorOverlay.vue' /* webpackChunkName: "components/error-overlay" */).then(c => wrapFunctional(c.default || c)),
  Footer: () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c)),
  FooterLogo: () => import('../../components/layout/FooterLogo.vue' /* webpackChunkName: "components/footer-logo" */).then(c => wrapFunctional(c.default || c)),
  Hamburger: () => import('../../components/layout/Hamburger.vue' /* webpackChunkName: "components/hamburger" */).then(c => wrapFunctional(c.default || c)),
  Header: () => import('../../components/layout/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c)),
  MenuPromo: () => import('../../components/layout/MenuPromo.vue' /* webpackChunkName: "components/menu-promo" */).then(c => wrapFunctional(c.default || c)),
  MobileNav: () => import('../../components/layout/MobileNav.vue' /* webpackChunkName: "components/mobile-nav" */).then(c => wrapFunctional(c.default || c)),
  Page: () => import('../../components/layout/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c)),
  SitePopup: () => import('../../components/layout/SitePopup.vue' /* webpackChunkName: "components/site-popup" */).then(c => wrapFunctional(c.default || c)),
  UserIcon: () => import('../../components/layout/UserIcon.vue' /* webpackChunkName: "components/user-icon" */).then(c => wrapFunctional(c.default || c)),
  LocationFeatured: () => import('../../components/locations/LocationFeatured.vue' /* webpackChunkName: "components/location-featured" */).then(c => wrapFunctional(c.default || c)),
  LocationListItem: () => import('../../components/locations/LocationListItem.vue' /* webpackChunkName: "components/location-list-item" */).then(c => wrapFunctional(c.default || c)),
  LocationPage: () => import('../../components/locations/LocationPage.vue' /* webpackChunkName: "components/location-page" */).then(c => wrapFunctional(c.default || c)),
  LocationSuggestions: () => import('../../components/locations/LocationSuggestions.vue' /* webpackChunkName: "components/location-suggestions" */).then(c => wrapFunctional(c.default || c)),
  Locations: () => import('../../components/locations/Locations.vue' /* webpackChunkName: "components/locations" */).then(c => wrapFunctional(c.default || c)),
  LocationsMap: () => import('../../components/locations/LocationsMap.vue' /* webpackChunkName: "components/locations-map" */).then(c => wrapFunctional(c.default || c)),
  LocationsPhoto: () => import('../../components/locations/LocationsPhoto.vue' /* webpackChunkName: "components/locations-photo" */).then(c => wrapFunctional(c.default || c)),
  LocationsResultsOld: () => import('../../components/locations/LocationsResultsOld.vue' /* webpackChunkName: "components/locations-results-old" */).then(c => wrapFunctional(c.default || c)),
  LocationsSearchColumn: () => import('../../components/locations/LocationsSearchColumn.vue' /* webpackChunkName: "components/locations-search-column" */).then(c => wrapFunctional(c.default || c)),
  SavedAddresses: () => import('../../components/locations/SavedAddresses.vue' /* webpackChunkName: "components/saved-addresses" */).then(c => wrapFunctional(c.default || c)),
  Checkout: () => import('../../components/order/Checkout.vue' /* webpackChunkName: "components/checkout" */).then(c => wrapFunctional(c.default || c)),
  Coupons: () => import('../../components/order/Coupons.vue' /* webpackChunkName: "components/coupons" */).then(c => wrapFunctional(c.default || c)),
  CustomerDetails: () => import('../../components/order/CustomerDetails.vue' /* webpackChunkName: "components/customer-details" */).then(c => wrapFunctional(c.default || c)),
  CustomerDetailsOld: () => import('../../components/order/CustomerDetailsOld.vue' /* webpackChunkName: "components/customer-details-old" */).then(c => wrapFunctional(c.default || c)),
  DateTimePicker: () => import('../../components/order/DateTimePicker.vue' /* webpackChunkName: "components/date-time-picker" */).then(c => wrapFunctional(c.default || c)),
  DateTimePickerNew: () => import('../../components/order/DateTimePickerNew.vue' /* webpackChunkName: "components/date-time-picker-new" */).then(c => wrapFunctional(c.default || c)),
  DeliveryDetails: () => import('../../components/order/DeliveryDetails.vue' /* webpackChunkName: "components/delivery-details" */).then(c => wrapFunctional(c.default || c)),
  DeliveryDetailsOld: () => import('../../components/order/DeliveryDetailsOld.vue' /* webpackChunkName: "components/delivery-details-old" */).then(c => wrapFunctional(c.default || c)),
  FiservPayment: () => import('../../components/order/FiservPayment.vue' /* webpackChunkName: "components/fiserv-payment" */).then(c => wrapFunctional(c.default || c)),
  HandoffDropdown: () => import('../../components/order/HandoffDropdown.vue' /* webpackChunkName: "components/handoff-dropdown" */).then(c => wrapFunctional(c.default || c)),
  HandoffDropdownOld: () => import('../../components/order/HandoffDropdownOld.vue' /* webpackChunkName: "components/handoff-dropdown-old" */).then(c => wrapFunctional(c.default || c)),
  OloPayment: () => import('../../components/order/OloPayment.vue' /* webpackChunkName: "components/olo-payment" */).then(c => wrapFunctional(c.default || c)),
  OrderAgain: () => import('../../components/order/OrderAgain.vue' /* webpackChunkName: "components/order-again" */).then(c => wrapFunctional(c.default || c)),
  OrderCategories: () => import('../../components/order/OrderCategories.vue' /* webpackChunkName: "components/order-categories" */).then(c => wrapFunctional(c.default || c)),
  OrderConfirmation: () => import('../../components/order/OrderConfirmation.vue' /* webpackChunkName: "components/order-confirmation" */).then(c => wrapFunctional(c.default || c)),
  OrderInformation: () => import('../../components/order/OrderInformation.vue' /* webpackChunkName: "components/order-information" */).then(c => wrapFunctional(c.default || c)),
  OrderItems: () => import('../../components/order/OrderItems.vue' /* webpackChunkName: "components/order-items" */).then(c => wrapFunctional(c.default || c)),
  OrderLocation: () => import('../../components/order/OrderLocation.vue' /* webpackChunkName: "components/order-location" */).then(c => wrapFunctional(c.default || c)),
  OrderLocationOld: () => import('../../components/order/OrderLocationOld.vue' /* webpackChunkName: "components/order-location-old" */).then(c => wrapFunctional(c.default || c)),
  OrderSummary: () => import('../../components/order/OrderSummary.vue' /* webpackChunkName: "components/order-summary" */).then(c => wrapFunctional(c.default || c)),
  OrderTotals: () => import('../../components/order/OrderTotals.vue' /* webpackChunkName: "components/order-totals" */).then(c => wrapFunctional(c.default || c)),
  PaymentConfirm: () => import('../../components/order/PaymentConfirm.vue' /* webpackChunkName: "components/payment-confirm" */).then(c => wrapFunctional(c.default || c)),
  PaymentFrame: () => import('../../components/order/PaymentFrame.vue' /* webpackChunkName: "components/payment-frame" */).then(c => wrapFunctional(c.default || c)),
  PickupMode: () => import('../../components/order/PickupMode.vue' /* webpackChunkName: "components/pickup-mode" */).then(c => wrapFunctional(c.default || c)),
  ProductPage: () => import('../../components/order/ProductPage.vue' /* webpackChunkName: "components/product-page" */).then(c => wrapFunctional(c.default || c)),
  QuantityTicker: () => import('../../components/order/QuantityTicker.vue' /* webpackChunkName: "components/quantity-ticker" */).then(c => wrapFunctional(c.default || c)),
  RewardDetails: () => import('../../components/order/RewardDetails.vue' /* webpackChunkName: "components/reward-details" */).then(c => wrapFunctional(c.default || c)),
  RewardDetailsOld: () => import('../../components/order/RewardDetailsOld.vue' /* webpackChunkName: "components/reward-details-old" */).then(c => wrapFunctional(c.default || c)),
  SavedGiftCards: () => import('../../components/order/SavedGiftCards.vue' /* webpackChunkName: "components/saved-gift-cards" */).then(c => wrapFunctional(c.default || c)),
  SavedPayments: () => import('../../components/order/SavedPayments.vue' /* webpackChunkName: "components/saved-payments" */).then(c => wrapFunctional(c.default || c)),
  Singles: () => import('../../components/order/Singles.vue' /* webpackChunkName: "components/singles" */).then(c => wrapFunctional(c.default || c)),
  SubmitOrder: () => import('../../components/order/SubmitOrder.vue' /* webpackChunkName: "components/submit-order" */).then(c => wrapFunctional(c.default || c)),
  TimeDetails: () => import('../../components/order/TimeDetails.vue' /* webpackChunkName: "components/time-details" */).then(c => wrapFunctional(c.default || c)),
  TimePicker: () => import('../../components/order/TimePicker.vue' /* webpackChunkName: "components/time-picker" */).then(c => wrapFunctional(c.default || c)),
  Tips: () => import('../../components/order/Tips.vue' /* webpackChunkName: "components/tips" */).then(c => wrapFunctional(c.default || c)),
  TipsOld: () => import('../../components/order/TipsOld.vue' /* webpackChunkName: "components/tips-old" */).then(c => wrapFunctional(c.default || c)),
  VehicleDetails: () => import('../../components/order/VehicleDetails.vue' /* webpackChunkName: "components/vehicle-details" */).then(c => wrapFunctional(c.default || c)),
  VehicleDetailsOld: () => import('../../components/order/VehicleDetailsOld.vue' /* webpackChunkName: "components/vehicle-details-old" */).then(c => wrapFunctional(c.default || c)),
  MenuCategories: () => import('../../components/products/MenuCategories.vue' /* webpackChunkName: "components/menu-categories" */).then(c => wrapFunctional(c.default || c)),
  MenuCategory: () => import('../../components/products/MenuCategory.vue' /* webpackChunkName: "components/menu-category" */).then(c => wrapFunctional(c.default || c)),
  MenuFeatured: () => import('../../components/products/MenuFeatured.vue' /* webpackChunkName: "components/menu-featured" */).then(c => wrapFunctional(c.default || c)),
  MenuItem: () => import('../../components/products/MenuItem.vue' /* webpackChunkName: "components/menu-item" */).then(c => wrapFunctional(c.default || c)),
  MenuPage: () => import('../../components/products/MenuPage.vue' /* webpackChunkName: "components/menu-page" */).then(c => wrapFunctional(c.default || c)),
  ProductConfiguration: () => import('../../components/products/ProductConfiguration.vue' /* webpackChunkName: "components/product-configuration" */).then(c => wrapFunctional(c.default || c)),
  ProductDetails: () => import('../../components/products/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c)),
  ProductImage: () => import('../../components/products/ProductImage.vue' /* webpackChunkName: "components/product-image" */).then(c => wrapFunctional(c.default || c)),
  ProductModifierList: () => import('../../components/products/ProductModifierList.vue' /* webpackChunkName: "components/product-modifier-list" */).then(c => wrapFunctional(c.default || c)),
  ProductModifierListLevel1: () => import('../../components/products/ProductModifierListLevel1.vue' /* webpackChunkName: "components/product-modifier-list-level1" */).then(c => wrapFunctional(c.default || c)),
  ProductModifiers: () => import('../../components/products/ProductModifiers.vue' /* webpackChunkName: "components/product-modifiers" */).then(c => wrapFunctional(c.default || c)),
  ProductModifiersLevel1: () => import('../../components/products/ProductModifiersLevel1.vue' /* webpackChunkName: "components/product-modifiers-level1" */).then(c => wrapFunctional(c.default || c)),
  CeoQuote: () => import('../../components/community/CeoQuote.vue' /* webpackChunkName: "components/ceo-quote" */).then(c => wrapFunctional(c.default || c)),
  CharityColumn: () => import('../../components/community/CharityColumn.vue' /* webpackChunkName: "components/charity-column" */).then(c => wrapFunctional(c.default || c)),
  CharitySection: () => import('../../components/community/CharitySection.vue' /* webpackChunkName: "components/charity-section" */).then(c => wrapFunctional(c.default || c)),
  FaqAccordion: () => import('../../components/faq/FaqAccordion.vue' /* webpackChunkName: "components/faq-accordion" */).then(c => wrapFunctional(c.default || c)),
  FaqSection: () => import('../../components/faq/FaqSection.vue' /* webpackChunkName: "components/faq-section" */).then(c => wrapFunctional(c.default || c)),
  QuestionList: () => import('../../components/faq/QuestionList.vue' /* webpackChunkName: "components/question-list" */).then(c => wrapFunctional(c.default || c)),
  CareersSection: () => import('../../components/about-us/CareersSection.vue' /* webpackChunkName: "components/careers-section" */).then(c => wrapFunctional(c.default || c)),
  HistoryContent: () => import('../../components/about-us/HistoryContent.vue' /* webpackChunkName: "components/history-content" */).then(c => wrapFunctional(c.default || c)),
  OurHistory: () => import('../../components/about-us/OurHistory.vue' /* webpackChunkName: "components/our-history" */).then(c => wrapFunctional(c.default || c)),
  YearsTimeline: () => import('../../components/about-us/YearsTimeline.vue' /* webpackChunkName: "components/years-timeline" */).then(c => wrapFunctional(c.default || c)),
  PrivacyPolicy: () => import('../../components/privacy-policy/PrivacyPolicy.vue' /* webpackChunkName: "components/privacy-policy" */).then(c => wrapFunctional(c.default || c)),
  TableOfContents: () => import('../../components/privacy-policy/TableOfContents.vue' /* webpackChunkName: "components/table-of-contents" */).then(c => wrapFunctional(c.default || c)),
  ContactQuestions: () => import('../../components/contact-us/ContactQuestions.vue' /* webpackChunkName: "components/contact-questions" */).then(c => wrapFunctional(c.default || c)),
  ContactUs: () => import('../../components/contact-us/ContactUs.vue' /* webpackChunkName: "components/contact-us" */).then(c => wrapFunctional(c.default || c)),
  BenefitItem: () => import('../../components/careers/BenefitItem.vue' /* webpackChunkName: "components/benefit-item" */).then(c => wrapFunctional(c.default || c)),
  Benefits: () => import('../../components/careers/Benefits.vue' /* webpackChunkName: "components/benefits" */).then(c => wrapFunctional(c.default || c)),
  BenefitsSection: () => import('../../components/careers/BenefitsSection.vue' /* webpackChunkName: "components/benefits-section" */).then(c => wrapFunctional(c.default || c)),
  EmployeeTestimonials: () => import('../../components/careers/EmployeeTestimonials.vue' /* webpackChunkName: "components/employee-testimonials" */).then(c => wrapFunctional(c.default || c)),
  JobApplyModal: () => import('../../components/careers/JobApplyModal.vue' /* webpackChunkName: "components/job-apply-modal" */).then(c => wrapFunctional(c.default || c)),
  JobApplyPage: () => import('../../components/careers/JobApplyPage.vue' /* webpackChunkName: "components/job-apply-page" */).then(c => wrapFunctional(c.default || c)),
  JobListings: () => import('../../components/careers/JobListings.vue' /* webpackChunkName: "components/job-listings" */).then(c => wrapFunctional(c.default || c)),
  JobSearch: () => import('../../components/careers/JobSearch.vue' /* webpackChunkName: "components/job-search" */).then(c => wrapFunctional(c.default || c)),
  FundraisingCard: () => import('../../components/fundraising/FundraisingCard.vue' /* webpackChunkName: "components/fundraising-card" */).then(c => wrapFunctional(c.default || c)),
  FundraisingCardSection: () => import('../../components/fundraising/FundraisingCardSection.vue' /* webpackChunkName: "components/fundraising-card-section" */).then(c => wrapFunctional(c.default || c)),
  FundraisingForm: () => import('../../components/fundraising/FundraisingForm.vue' /* webpackChunkName: "components/fundraising-form" */).then(c => wrapFunctional(c.default || c)),
  OclubAppDownload: () => import('../../components/oclub/OclubAppDownload.vue' /* webpackChunkName: "components/oclub-app-download" */).then(c => wrapFunctional(c.default || c)),
  OclubHero: () => import('../../components/oclub/OclubHero.vue' /* webpackChunkName: "components/oclub-hero" */).then(c => wrapFunctional(c.default || c)),
  OclubRewards: () => import('../../components/oclub/OclubRewards.vue' /* webpackChunkName: "components/oclub-rewards" */).then(c => wrapFunctional(c.default || c)),
  CheckBalanceModal: () => import('../../components/gift-cards/CheckBalanceModal.vue' /* webpackChunkName: "components/check-balance-modal" */).then(c => wrapFunctional(c.default || c)),
  GiftCardHero: () => import('../../components/gift-cards/GiftCardHero.vue' /* webpackChunkName: "components/gift-card-hero" */).then(c => wrapFunctional(c.default || c)),
  GiftCardItem: () => import('../../components/gift-cards/GiftCardItem.vue' /* webpackChunkName: "components/gift-card-item" */).then(c => wrapFunctional(c.default || c)),
  GiftCardPage: () => import('../../components/gift-cards/GiftCardPage.vue' /* webpackChunkName: "components/gift-card-page" */).then(c => wrapFunctional(c.default || c)),
  GiftCardWarning: () => import('../../components/gift-cards/GiftCardWarning.vue' /* webpackChunkName: "components/gift-card-warning" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
