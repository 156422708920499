//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { errorConstants } from '../../plugins/errorConstants'

export default {
  computed: {
    ...mapState([ 'errors', 'redirect' ])
  },
  methods: {
    clearErrors() {
      if(this.redirect && this.redirect != undefined && this.redirect != '/undefined' && this.redirect != 'undefined') {
        let redirect = this.redirect
        this.$store.commit('setRedirect', null)
        this.$router.push(redirect)
      }
      this.$store.commit('setErrors', [])
    },
    mapErrors(error) {
      const errorFromConstant = errorConstants[error]

      if(!errorFromConstant) {
        return error
      }

      return errorFromConstant
    }
  }
}
