//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'navMenus', 'user', 'location', 'order', 'showCart' ]),
    mq() {
      return this.$mq
    },
    menu() {
      return this.navMenus['menu-header'].filter(link => link.title)
    },
    showBag() {
      if (this.order && this.order.products && this.order.products.length || this.location) {
        return true
      }
      return false
    },
    productCount() {
      const productsInCart = this.order?.products
        .filter(product => !product.name.toLowerCase().includes('utensils'))
        .filter(product => product.name.toLowerCase() !== 'no rolls') || []

      return productsInCart.length || 0
    }
  },
  methods: {
    toggleCart() {
      if (!this.location) {
        this.$router.push('/locations')
        return
      }

      this.$store.commit('setShowCart', !this.showCart)
    }
  }
}
