const moment = require('moment')
let refreshing = false

export default (context, inject) => {
  const api = {
    async request(obj, verify = false, surpress = false, loader = true) {
      obj.url = `${process.env.API_URL_LOCAL || window.location.origin}/api/v1/${obj.headers.path}`
      delete obj.headers.path
      if (process.env.REQUIRE_VERIFY && verify) {
        obj.headers.verify = await context.$recaptcha.execute('login')
      }
      if (process.env.API_ENV) {
        obj.headers.env = process.env.API_ENV
      } else if (context.store.state.env) {
        obj.headers.env = context.store.state.env
      }
      obj.headers.client_type = context.store.state.client
      context.store.commit('setErrors', [])
      if (loader) {
        context.store.commit('setLoading', true)
      }
      return context
        .$axios(obj)
        .then((res) => {
          context.store.commit('setLoading', false)
          return res.data
        })
        .catch((err) => {
          context.store.commit('setLoading', false)
          if (surpress === false) {
            if (Array.isArray(err.response.data)) {
              if (typeof err.response.data[0] === 'string') {
                context.store.commit('setErrors', err.response.data)
              } else {
                let errors = []
                err.response.data.map(e => {
                  errors.push(e.message)
                })
                context.store.commit('setErrors', errors)
              }
            } else if (err.response.data.message) {
              if (err.response.data.message == 'Could not load shopping basket') {
                context.store.commit('setOrder', null)
                // context.store.commit('setErrors', ['There was an issue with your cart. Please start a new order.'])
                if (context.route.path.match(/\/order\/checkout/)) {
                  context.store.commit('setRedirect', '/order/')
                }
              } else {
                const errorContent = err.response.data

                if (errorContent.error_code) {
                  context.store.commit('setErrors', [err.response.data.error_code])
                } else {
                  context.store.commit('setErrors', [err.response.data.message])
                }
              }
            } else if (err.response.data?.password?.toLowerCase() === "incorrect format.") {
              context.store.commit('setErrors', [
                'Your password contains unauthorized characters. Please check the password requirements and try again.'
              ])
            } else {
              context.$bugsnag.notify(err)
              context.store.commit('setErrors', [
                'Connection issue. Please try again.'
              ])
            }
          }
          return null
        })
    },
    copy(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    delay(ms = 1000) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    // Email subscription
    async subscribe(customer) {
      const subscription = await this.request({
        method: 'post',
        headers: {
          path: 'eclub/subscribe'
        },
        data: customer
      })
      return subscription
    },
    // Users
    async createUser(customer) {
      const user = await this.request({
        method: 'post',
        headers: {
          path: 'members/create'
        },
        data: customer
      }, true)
      if (user) {
        context.store.commit('setToken', user.tokens)
      }
      return user
    },
    async loginUser(creds) {
      const user = await this.request({
        method: 'post',
        headers: {
          path: 'members/signin'
        },
        data: creds
      }, true)
      if (user) {
        context.store.commit('setToken', user.tokens)
      } else {
        context.store.commit('setErrors', ['O No! Incorrect email and/or password. Check your credentials and try again, or contact us if you have an issue.'])
      }
      return user
    },
    async loginSSO(data) {
      let user = await this.request({
        method: 'post',
        headers: {
          path: 'members/sso',
        },
        data: data
      })
      if (user) {
        context.store.commit('setToken', user.tokens)
      }
      return user
    },
    // async evaluateToken() {
    //   if(refreshing === false && context.store.state.token) {
    //     let now = parseInt(moment().format('X'))
    //     let expires = context.store.state.token.access.expires
    //     if(now >= expires) {
    //       refreshing = true
    //       let refresh = await this.refreshToken()
    //       refreshing = false
    //       return refresh ? true : false
    //     } else {
    //       return context.store.state.token ? true : false
    //     }
    //   } else if(refreshing === true) {
    //     while(refreshing === true) {
    //       await this.delay()
    //     }
    //     return context.store.state.token ? true : false
    //   } else {
    //     return context.store.state.token ? true : false
    //   }
    // },
    // async refreshToken() {
    //   let token = await this.request({
    //     method: 'post',
    //     headers: {
    //       path: 'members/refresh',
    //       token: context.store.state.token.access.token
    //     }
    //   }, false, true)
    //   if(token) {
    //     context.store.commit('setToken', token)
    //     return true
    //   } else {
    //     await this.logoutUser()
    //     window.location.href = '/account/signin'
    //     return false
    //   }
    // },
    async logoutUser() {
      await this.request({
        method: 'delete',
        headers: {
          path: 'members/signout',
          token: context.store.state.token.access.token
        }
      })
      await this.abandonOrder()
      context.store.commit('setRedirect', null)
      context.store.commit('setUser', null)
      context.store.commit('setToken', null)
      return true
    },
    async deleteAccount() {
      await this.request({
        method: 'delete',
        headers: {
          path: 'members',
          token: context.store.state.token.access.token
        }
      })
      context.store.commit('setUser', null)
      context.store.commit('setMenu', null)
      context.store.commit('setLocation', null)
      await this.abandonOrder()
      return true
    },
    async getUser(withTokens = false) {
      let request = {
        method: 'get',
        headers: {
          path: 'members/get',
          token: context.store.state.token.access.token
        }
      }
      if (context.store.state.token.id) {
        request.params = {
          memberID: context.store.state.token.id
        }
      }
      if (withTokens) {
        if (!request.params) {
          request.params = {
            withTokens: true
          }
        } else {
          request.params.withTokens = true
        }
      }
      const user = await this.request(request)
      if (user) {
        context.store.commit('setUser', user)
      }
      return user
    },
    async updateUser(d) {
      const user = await this.request({
        method: 'put',
        headers: {
          path: 'members/update',
          token: context.store.state.token.access.token
        },
        data: d
      })
      if (user) {
        context.store.commit('setUser', Object.assign(this.copy(context.store.state.user), d))
      }
      return user
    },
    async setFaveLocation(id) {
      const fave = await this.request({
        method: 'post',
        headers: {
          path: `members/faves/locations/${id}`,
          token: context.store.state.token.access.token
        }
      })
      return fave
    },
    async deleteFaveLocation(id) {
      const fave = await this.request({
        method: 'delete',
        headers: {
          path: `members/faves/locations/${id}`,
          token: context.store.state.token.access.token
        }
      })
      if (fave != null) {
        const user = JSON.parse(JSON.stringify(context.store.state.user))
        user.favelocations.map((f, i) => {
          if (f.menu_id == id) {
            user.favelocations.splice(i, 1)
          }
        })
        context.store.commit('setUser', user)
      }
      return fave
    },
    // Payments
    async getPayments() {
      const payments = await this.request({
        method: 'get',
        headers: {
          path: `members/payments`,
          token: context.store.state.token.access.token
        }
      })
      if (payments) {
        const user = JSON.parse(JSON.stringify(context.store.state.user))
        user.payments = payments.payments
        context.store.commit('setUser', user)
      }
      return payments
    },
    async deletePayment(pid) {
      const payments = await this.request({
        method: 'delete',
        headers: {
          path: `members/payments/${pid}`,
          token: context.store.state.token.access.token
        }
      })
      if (payments != null) {
        const user = JSON.parse(JSON.stringify(context.store.state.user))
        user.payments.map((p, i) => {
          if (p.account.id == pid) {
            user.payments.splice(i, 1)
          }
        })
        context.store.commit('setUser', user)
      }
      return payments
    },
    getPaymentForm() {
      return this.request({
        method: 'post',
        headers: {
          path: `payments/form`
        },
        data: {
          id: context.store.state.token.payments.access.id
        }
      })
    },
    submitPayment(account) {
      return this.request({
        method: 'post',
        headers: {
          path: `payments/submit`,
          token: context.store.state.token.access.token,
          payment_token: context.store.state.token.payments.access.token
        },
        data: {
          id: context.store.state.token.payments.access.id,
          order_id: context.store.state.order.id,
          store_id: context.store.state.order.menu_id,
          amount: context.store.state.order.totals.total,
          account
        }
      })
    },
    // Addresses
    async getAddresses() {
      const addresses = await this.request({
        method: 'get',
        headers: {
          path: `members/addresses`,
          token: context.store.state.token.access.token
        }
      })
      if (addresses) {
        const user = JSON.parse(JSON.stringify(context.store.state.user))
        user.addresses = addresses.addresses
        context.store.commit('setUser', user)
      }
    },
    async deleteAddress(aid) {
      const addresses = await this.request({
        method: 'delete',
        headers: {
          path: `members/addresses/${aid}`,
          token: context.store.state.token.access.token
        }
      })

      const user = JSON.parse(JSON.stringify(context.store.state.user))
      user.addresses.map((a, i) => {
        if (a.id == aid) {
          user.addresses.splice(i, 1)
        }
      })
      context.store.commit('setUser', user)
      return addresses
    },
    // Passwords
    async changePassword(newpassword, oldpassword) {
      return await this.request({
        method: 'post',
        headers: {
          path: 'members/password',
          token: context.store.state.token.access.token
        },
        data: {
          new: newpassword,
          current: oldpassword
        }
      })
    },
    async forgotPassword(email) {
      return await this.request({
        method: 'post',
        headers: {
          path: 'members/forgotpassword'
        },
        data: {
          email
        }
      }, true)
    },
    async resetPassword(token, email, password) {
      return await this.request({
        method: 'post',
        headers: {
          path: 'members/resetpassword'
        },
        data: {
          token,
          email,
          password
        }
      })
    },
    // Locations
    async findLocations(loc, surpress = false) {
      const locations = await this.request({
        method: 'get',
        headers: {
          path: 'locations/search'
        },
        params: loc
      }, false, surpress)
      if (locations) {
        if (locations.count > 0) {
          context.store.commit('setLocationList', locations.locations)
        } else {
          context.store.commit('setLocationList', false)
        }
      } else {
        context.store.commit('setLocationList', null)
      }
      return locations
    },
    async findDeliveryLocations(data) {
      const locations = await this.request({
        method: 'post',
        headers: {
          path: 'locations/delivery'
        },
        data
      })
      return locations
    },
    async getLocations() {
      const request = {
        method: 'get',
        headers: {
          path: `locations`
        }
      }
      const locations = await this.request(request)
      return locations
    },
    async getLocation(id, byMenuId = false) {
      const request = {
        method: 'get',
        headers: {
          path: `locations/${id}`
        }
      }
      if (byMenuId) {
        request.params = {
          byMenuId: true
        }
      }
      const location = await this.request(request)
      if (location) {
        context.store.commit('setLocation', location)
      }
      return location
    },
    // Menus
    async getMenu(surpress = false, loader = true) {
      if (context.store.state.location.menu_id) {
        const menu = await this.request({
          method: 'get',
          headers: {
            path: `menus/${context.store.state.location.menu_id}`
          }
        }, false, surpress, loader)
        if (menu) {
          context.store.commit('setMenu', menu)
          return true
        } else {
          return false
        }
      } else {
        context.store.commit('setMenu', null)
        return false
      }
    },
    async getDefaultMenu() {
      const DEFAULT_MENU_ID = '185530' // O'Charley's Onboarding Demo Vendor Location

      const menu = await this.request({
        method: 'get',
        headers: {
          path: `menus/${DEFAULT_MENU_ID}`
        }
      }, false)

      if (menu) {
        context.store.commit('setDefaultMenu', menu)
        return menu
      } else {
        return false
      }
    },
    getProduct(pid) {
      let req = {
        method: 'get',
        headers: {
          path: `menus/${context.store.state.location.menu_id}/products/${pid}`
        }
      }
      if (context.store.state.token) {
        req.headers.token = context.store.state.token.access.token
      }
      return this.request(req)
    },
    // Order History
    async getRecentOrders() {
      if (context.store.state.token) {
        const orders = await this.request({
          methods: 'get',
          headers: {
            path: 'orders/recent',
            token: context.store.state.token.access.token
          }
        })
        if (orders) {
          context.store.commit('setOrderHistory', orders.orders)
        }
        return orders
      }
    },
    async createReorder(id) {
      const order = await this.request({
        method: 'post',
        headers: {
          path: 'orders/reorder',
          token: context.store.state.token.access.token
        },
        data: {
          id
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    // Favorite Orders
    async getFaveOrders() {
      const orders = await this.request({
        method: 'get',
        headers: {
          path: 'orders/faves',
          token: context.store.state.token.access.token
        }
      })
      return orders
    },
    async createFaveOrder(id, name) {
      const order = await this.request({
        method: 'post',
        headers: {
          path: 'orders/faves',
          token: context.store.state.token.access.token
        },
        data: {
          id,
          description: name
        }
      })
      return order
    },
    async createFaveReorder(id) {
      const order = await this.request({
        method: 'post',
        headers: {
          path: 'orders/faves/reorder',
          token: context.store.state.token.access.token
        },
        data: {
          id
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    // Orders
    async createOrder() {
      let order = null
      const headers = {
        path: 'orders/create'
      }
      if (context.store.state.token) {
        headers.token = context.store.state.token.access.token
      }
      order = await this.request({
        method: 'post',
        headers,
        data: {
          menu_id: context.store.state.location.menu_id
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async getOrder() {
      let order = await this.request({
        method: 'get',
        headers: {
          path: `orders/${context.store.state.order.id}`
        }
      }, false, false, false)
      if (order) {
        context.store.commit('setOrder', order)
      }
    },
    async abandonOrder() {
      if (context.store.state.order && context.store.state.token) {
        await this.request({
          method: 'delete',
          headers: {
            path: `orders/${context.store.state.order.id}/abandon`,
            token: context.store.state.token.access.token
          }
        })
        context.store.commit('setOrder', null)
        return true
      }
    },
    async transferOrder() {
      let order = await this.request({
        method: 'post',
        headers: {
          path: `orders/${context.store.state.order.id}/transfer`
        },
        data: {
          menu_id: context.store.state.location.menu_id
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async retrieveOrder(oid) {
      const order = await this.request({
        method: 'get',
        headers: {
          path: `orders/${oid}`
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async addToOrder(prods) {
      if (!context.store.state.order) {
        await this.createOrder(context.store.state.location.menu_id)
      }
      let config = {
        method: 'post',
        headers: {
          path: `orders/${context.store.state.order.id}/products`
        },
        data: {
          products: prods
        }
      }
      if (context.store.state.token) {
        config.headers.token = context.store.state.token.access.token
      }
      const order = await this.request(config, false, false, false)
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async updateInOrder(prods) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/products`
        },
        data: {
          products: prods
        }
      }, true, false, false)
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async removeFromOrder(pid) {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/products/${pid}`
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async getUpsells(pid) {
      if (context.store.state.order) {
        return this.request({
          method: 'get',
          headers: {
            path: `upsells/${context.store.state.order.id}/product/${pid}`,
            token: context.store.state.token ? context.store.state.token.access.token : null
          }
        }, false, true)
      } else {
        return []
      }
    },
    getUpsellsForCart() {
      if (context.store.state.order) {
        let request = {
          method: 'get',
          headers: {
            path: `upsells/${context.store.state.order.id}`
          }
        }
        if (context.store.state.user) {
          request.headers.params = {
            user: context.store.state.user.email
          }
        }
        return this.request(request, false, true, false)
      } else {
        return null
      }
    },
    addUpsellItem(uid, qty) {
      let items = [{
        id: uid,
        quantity: qty,
      }]
      return this.request({
        method: 'post',
        headers: {
          path: `upsells/${context.store.state.order.id}`,
          token: context.store.state.token ? context.store.state.token.access.token : null
        },
        data: {
          items
        }
      })
    },
    async validateOrder() {
      const totals = await this.request({
        method: 'post',
        headers: {
          path: `orders/${context.store.state.order.id}/validate`
        }
      })
      if (totals) {
        const order = JSON.parse(JSON.stringify(context.store.state.order))
        order.taxes = totals.taxes
        order.totals.tax = totals.totals.tax
        order.totals.total = totals.totals.total
        order.totals.subtotal = totals.totals.subtotal
        order.totals.fees = totals.totals.fees
        if (order.time.mode == 'asap') {
          order.time.earliest = totals.time
        }

        context.store.commit('setOrder', order)
        context.store.commit('setSupports', totals.supports)
        totals.billing.map(b => {
          if (b.type == 'creditcard' && b.accounts.length > 0) {
            context.store.commit('setAccounts', b.accounts)
            context.store.commit('setSavedPayments', true)
            b.accounts.map(a => {
              if (a.account.default) {
                context.store.commit('setSelectedAccount', a.account.id)
              }
            })
          } else if (b.type == 'creditcard') {
            context.store.commit('setAccounts', [])
            context.store.commit('setSavedPayments', false)
          }

          if (b.type == 'giftcard' && b.accounts.length > 0) {
            context.store.commit('setSavedGiftcards', b.accounts)
          }
        })
      }
      return totals
    },
    async setHandoff(mode) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/handoff/${mode}`
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async setAddress(address) {
      const type = context.store.state.location.services.dispatch ? 'dispatch' : 'delivery'
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/address`
        },
        data: {
          type,
          address
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async setCustomFields(fields) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/custom`
        },
        data: {
          fields
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    getCalendar(from, to) {
      return this.request({
        method: 'get',
        headers: {
          path: `menus/${context.store.state.location.menu_id}/times`
        },
        params: {
          from,
          to
        }
      })
    },
    async setTime(time) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/time`
        },
        data: {
          time
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async deleteTime() {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/time`
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async addTip(amount) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/tip`
        },
        data: {
          amount
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async addCoupon(code) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/coupon`
        },
        data: {
          code
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async removeCoupon() {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/coupon`
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    giftCardBalance(number, pin) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${context.store.state.order.id}/giftcard`
        },
        data: {
          number,
          pin
        }
      }, true)
    },
    getBasketToken() {
      const headers = {
        path: `orders/${context.store.state.order.id}/token`
      }
      if (context.store.state.token) {
        headers.token = context.store.state.token.access.token
      }
      return this.request({
        method: 'post',
        headers
      })
    },
    submitOrder(billing) {
      let headers = {
        path: `orders/${context.store.state.order.id}/submit`
      }
      let data = {
        usertype: 'guest',
        payments: billing
      }
      if (context.store.state.token.access.token) {
        data.usertype = 'user'
        headers.token = context.store.state.token.access.token
      } else {
        data.first_name = context.store.customer.user.first_name
        data.last_name = context.store.customer.user.last_name
        data.email = context.store.customer.user.email
        data.phone = context.store.customer.user.phone
      }
      if (context.store.state.instorePayment) { // in store payment
        data.amount = {
          total: context.store.state.order.totals.total,
          tip: context.store.state.order.totals.tip
        }
        data.payments[0].type = 'payinstore'
        data.payments[0].id = 2
        context.store.commit('setInstorePayment', false)
      }
      return this.request({
        method: 'post',
        headers,
        data
      })
    },
    trackConversion(bid, oid, products) {
      let req = {
        method: 'post',
        headers: {
          path: `orders/${bid}/track`
        },
        data: {
          id: oid,
          products
        }
      }
      if (context.store.state.token) {
        req.headers.token = context.store.state.token.access.token
      }
      return this.request(req)
    },
    trackPaymentFailure(oid, error) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${oid}/track`
        },
        data: {
          error,
          type: 'ccsf'
        }
      })
    },
    getOrderStatus(id) {
      return this.request({
        method: 'get',
        headers: {
          path: `orders/${id}/status`
        }
      })
    },
    cancelOrder(id) {
      return this.request({
        method: 'delete',
        headers: {
          path: `orders/${id}`
        }
      })
    },
    getOffers() {
      return this.request({
        method: 'get',
        headers: {
          path: `members/offers/all`,
          token: context.store.state.token.access.token
        },
      })
    },
    getBalance() {
      return this.request({
        method: 'get',
        headers: {
          path: `members/balance`,
          token: context.store.state.token.access.token
        },
      })
    },
    // getRedeemedOffers() {
    //   return this.request({
    //     method: 'post',
    //     headers: {
    //       path: `members/balance?memberID=${context.store.state.token.id}`,
    //       token: context.store.state.token.access.token
    //     },
    //   }, false, false, false)
    // },
    redeemOfferToAccount(price, code) {
      return this.request({
        method: 'post',
        headers: {
          path: `members/redeem?memberID=${context.store.state.token.id}`,
          token: context.store.state.token.access.token
        },
        data: {
          price: price,
          code: code
        }
      }, false, false, false)
    },
    getOffersForOrder() {
      if (context.store.state.token) {
        return this.request({
          method: 'get',
          headers: {
            path: `orders/${context.store.state.order.id}/rewards`,
            token: context.store.state.token.access.token
          }
        })
      } else {
        return []
      }
    },
    async applyOfferToOrder(memberid, reference) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/rewards`,
          token: context.store.state.token.access.token
        },
        data: {
          memberid: memberid,
          references: [
            reference
          ]
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async removeOfferFromOrder(id) {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/rewards/${id}`,
          token: context.store.state.token.access.token
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    }
  }

  inject('api', api)
  context.$api = api
}
