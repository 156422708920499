//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations  } from 'vuex'

export default {
  computed: {
    ...mapState([ 'location', 'order', 'menu', 'loading', 'errors', 'showBanner', 'token', 'user', 'showSitePopup', 'sitePopup' ]),
    isHome() {
      return this.$route.path === '/'
    },
    isPageWithPopup() {
      return this.sitePopup.places_to_show.some(placeLink => {
        if (placeLink.page.cached_url === 'home') {
          return this.$route.path === '/'
        }

        return this.$route.path.includes(placeLink.page.cached_url)
      })
    },
    gtmId() {
      return process.env.GTM_ID
    },
    gtmScriptLink() {
      return `https://www.googletagmanager.com/gtag/js?id=${this.gtmId}`
    }
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch(err) {
      console.error(err)
    }
    if(localStorage.getItem('olo_orderid')) {
      this.$store.commit('setOrder', {id: localStorage.getItem('olo_orderid')})
      await this.$api.getOrder()
      await this.$api.getLocation(this.order.menu_id, true)
      await this.$api.getMenu()
      localStorage.removeItem('olo_orderid')
    }

    if(this.$route.query.store) {
      await this.$api.getLocation(this.$route.query.store,true)
      await this.$api.getMenu(true, false)
      if(this.order && this.order.menu_id != this.location.menu_id) {
        await this.$api.transferOrder()
      } else {
        await this.$api.createOrder()
      }
    } else if(this.$route.query.menu_id) {
      await this.$api.getLocation(this.$route.query.menu_id, true)
      await this.$api.getMenu(true, false)
      if(this.order && this.order.menu_id != this.location.menu_id) {
        await this.$api.transferOrder()
      } else {
        await this.$api.createOrder()
      }
    }
    // Set order
    // if(this.location) {
    //   await this.$api.getMenu(true, false)
    //   let order = null
    //   if(this.order && this.order.menu_id != this.location.menu_id) {
    //     order = await this.$api.transferOrder()
    //   } else {
    //     order = await this.$api.createOrder()
    //   }
    //   if(order) {
    //     this.$router.push('/order/')
    //   }
    // }

    if (this.$cookiz.get('ocharleysPopup') !== 'closed') {
      this.setShowSitePopup(true)
    }
  },
  methods: {
    ...mapActions([ 'fanFave' ]),
    ...mapMutations(['setShowSitePopup']),
  },
  watch: {
    '$route.path'(e) {
      // this.$store.commit('setErrors', [])
      this.$store.commit('setShowCart', false)
      if (this.$mq == 'sm' || this.$mq == 'md') {
        this.$store.commit('setShowNav', false)
      } else {
        this.$store.commit('setShowNav', true)
      }
      window.scroll({ top: 0, left: 0, behavior: 'smooth' }) // scroll to top on route change
    }
  }
}
