//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    quantity: {
      type: Number,
      default: 0
    }
  }
};
