//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState([ 'navMenus', 'showNav', 'banner', 'user', 'location', 'order' ]),
  },
  mounted() {
    window.addEventListener('resize',this.resize)
  },
  destroyed() {
    window.removeEventListener('resize',this.resize)
  },
  methods: {
    toggleNav() {
      this.$store.commit('setShowNav', !this.showNav)
    },
    resize() {
      if (this.$mq == 'lg' || this.$mq == 'xl') {
        this.$store.commit('setShowNav', false)
      }
    }
  },
}
