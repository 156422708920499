//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'options', 'navMenus' ]),
    year() {
      return moment().year()
    },
    copyright() {
      return this.options['copyright']
    },
    menu() {
      return this.navMenus['menu-footer-main']
    },
    companyMenu() {
      return this.navMenus['company-menu']
    },
    quickLinksMenu() {
      return this.navMenus['quick-links-menu']
    },
    socialMenu() {
      return this.navMenus['social-menu']
    },
    mq() {
      return this.$mq
    }
  },
}
