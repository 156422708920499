export default (context, inject) => {
  const hirebridge = {
    async request(obj) {
      obj.url = `${window.location.origin}/api/hirebridge/${obj.headers.path}`;

      context.store.commit("setLoading", true);
      context.store.commit("setErrors", []);

      return context
        .$axios(obj)
        .then(res => {
          context.store.commit("setLoading", false);
          return res.data;
        })
        .catch(err => {
          console.error(err);

          context.store.commit("setLoading", false);

          if (Array.isArray(err.response.data)) {
            if (typeof err.response.data[0] === "string") {
              context.store.commit("setErrors", err.response.data);
            } else {
              let errors = [];
              err.response.data.map(e => {
                errors.push(e.message);
              });
              context.store.commit("setErrors", errors);
            }
          } else {
            context.$bugsnag.notify(err);
            context.store.commit("setErrors", [
              "Connection issue. Please try again."
            ]);
          }
        });
    },
    async getJobs(searchQuery, resultsPerPage = 10) {
      const baseJobDetailsPath = "GetJobs";
      const cidParams = ["cid=6972", "othercid=6921", "othercid=6985"];
      const queryParams = [];

      if (searchQuery.page) {
        const startRow = (searchQuery.page - 1) * resultsPerPage + 1;
        const endRow = searchQuery.page * resultsPerPage;

        queryParams.push(`startrow=${startRow}&endrow=${endRow}`);
      }

      // if (searchQuery.jobType) {
      //   queryParams.push(`jobtype=${searchQuery.jobType}`);
      // }

      if (searchQuery.state) {
        queryParams.push(`state=${searchQuery.state}`);
      }

      if (searchQuery.city) {
        queryParams.push(`city=${searchQuery.city}`);
      }

      if (searchQuery.jid) {
        queryParams.push(`jid=${searchQuery.jid}`);
      }

      const headers = {
        path: `${baseJobDetailsPath}?${cidParams.concat(queryParams).join("&")}`
      };

      try {
        const jobs = await this.request({
          method: "get",
          headers
        });

        return jobs;
      } catch (error) {
        console.error("Error fetching jobs:", error);
        throw error;
      }
    }
  };

  inject("hirebridge", hirebridge);
  context.$hirebridge = hirebridge;
};
