import { render, staticRenderFns } from "./LocationHandoff.vue?vue&type=template&id=3c6b9eb8&scoped=true&"
import script from "./LocationHandoff.vue?vue&type=script&lang=js&"
export * from "./LocationHandoff.vue?vue&type=script&lang=js&"
import style0 from "./LocationHandoff.vue?vue&type=style&index=0&id=3c6b9eb8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c6b9eb8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconMarkerLarge: require('/opt/build/repo/components/icons/IconMarkerLarge.vue').default})
