//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["location", "order"]),
    locationName() {
      return this.order.handoff !== "dispatch"
        ? this.location.name
        : this.order.address.address;
    }
  },
  methods: {
    async setHandoff(handoff) {
      await this.$api.setHandoff(handoff);
    }
  }
};
