//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'loading', 'errors' ])
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch(err) {
      console.error(err)
    }
  },
  watch: {
    '$route.path'(e) {
      // setTimeout(function() {
      //   this.$nextTick(() => {
      //     this.setLayout()
      //   })
      // }.bind(this), 100)
      this.$store.commit('setErrors', [])
      window.scroll({ top: 0, left: 0, behavior: 'smooth' }) // scroll to top on route change
    }
  }
}
