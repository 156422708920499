import { render, staticRenderFns } from "./MobileNav.vue?vue&type=template&id=55dfe626&scoped=true&"
import script from "./MobileNav.vue?vue&type=script&lang=js&"
export * from "./MobileNav.vue?vue&type=script&lang=js&"
import style0 from "./MobileNav.vue?vue&type=style&index=0&id=55dfe626&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55dfe626",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuLink: require('/opt/build/repo/components/common/MenuLink.vue').default,IconUserGreen: require('/opt/build/repo/components/icons/IconUserGreen.vue').default,LocationHandoff: require('/opt/build/repo/components/common/LocationHandoff.vue').default})
