//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'
import moment from 'moment-timezone'

export default {
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['showSitePopup', 'menuHeader']),
    shouldShowPopup() {
      if (!this.showSitePopup) {
        return false
      }

      if (!this.blok.start && !this.blok.end) {
        return true
      }

      const today = moment().tz('America/New_York')
      const start = this.blok.start ? moment(this.blok.start, 'YYYY-MM-DD, hh:mm').tz('America/New_York') : null
      const end = this.blok.end ? moment(this.blok.end, 'YYYY-MM-DD, hh:mm').tz('America/New_York') : null

      // Invalid start date
      if (start && !start.isValid()) {
        return false
      }

      // Invalid end date
      if (end && !end.isValid()) {
        return false
      }

      // Start date is after end date
      if (start && end && start.isAfter(end)) {
        return false
      }

      // If there is no start date, show popup if end date is in the future
      if (!this.blok.start && today.isSameOrBefore(end)) {
        return true
      }

      // If there is no end date, show popup if start date is in the past
      if (!this.blok.end && today.isSameOrAfter(start)) {
        return true
      }

      if (today.isSameOrAfter(start) && today.isSameOrBefore(end)) {
        return true
      }

      return false
    },
    popupLink() {
      if (!this.blok.url) {
        return false
      }

      const element = this.blok.url.linktype === 'story' ? 'nuxt-link' : 'a'
      const link = this.blok.url.linktype === 'story' ? `/${this.blok.url.cached_url}` : this.blok.url.url

      return {
        element,
        link
      }
    },
    isMobile() {
      return this.$mq === 'sm'
    }
  },
  mounted() {
    if (this.shouldShowPopup) {
      if (this.$cookiz.get('ocharleysPopup') === 'closed') {
        this.setShowSitePopup(false)
      }
    }

    this.$nextTick(() => {
      const popup = this.$refs.sitePopup

      if (popup) {
        popup.focus()
      } 

      document.addEventListener('keydown', this.trapFocus)
    })
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.trapFocus);
  },
  methods: {
    ...mapMutations(['setShowSitePopup']),
    closePopup() {
      this.setShowSitePopup(false)
      
      // expires in 1 day
      const expiresTime = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      this.$cookiz.set('ocharleysPopup', 'closed', { expires: expiresTime })
    },
    trapFocus(e) {
      if (!this.shouldShowPopup) return;

      const focusableElements = this.$refs.sitePopup.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement = focusableElements[focusableElements.length - 1];

      if (e.key === 'Tab') {
        if (e.shiftKey) /* shift + tab */ {
          if (document.activeElement === firstFocusableElement) {
            e.preventDefault();
            lastFocusableElement.focus();
          }
        } else /* tab */ {
          if (document.activeElement === lastFocusableElement) {
            e.preventDefault();
            firstFocusableElement.focus();
          }
        }
      }

      if (e.key === 'Escape') {
        this.setShowSitePopup(false);
      }
    },
  },
}
