/* DO NOT EDIT OUTSIDE OF MASTER */

export default (context, inject) => {
  const storyblok = {
    async getStories(type, page = 1, per_page = 100) {
      // When the site is running the build process for production
      if(process.server && process.env.NODE_ENV != 'development' && process.env.STORYBLOK_PREVIEW != 'true') {
        return await context.$storage.getFile(type)
      } else {
        return context.$storyapi.get('cdn/stories', {
          starts_with: type,
          version: process.env.STORYBLOK_VERSION || 'published',
          page: page,
          per_page: per_page
        })
          .then(res => {
            return res.data.stories
          })
          .catch(err => {
            return null
          })
      }
    },
    async getStory(slug) {
      let cleanSlug = slug.replace(/\/$/, '')
      // When the site is running the build process for production
      if(process.server && process.env.NODE_ENV != 'development') {
        return await context.$storage.getFile(cleanSlug)
      // When the site is in development or set to draft
      } else if(process.env.NODE_ENV == 'development' || process.env.STORYBLOK_PREVIEW == 'true') {
        return await context.$storyapi.get(`cdn/stories/${cleanSlug}`, {
          version: process.env.STORYBLOK_VERSION || 'published'
        }).then(res => {
          return res.data.story
        }).catch(err => {
          return null
        })
      // When the site is in production and running client side
      } else {
        return await context.$axios({
          method: 'get',
          url: `${window.location.origin}/_data/${cleanSlug}.json`
        }).then(res => {
          return res.data
        }).catch(err => {
          return null
        })
      }
    }
  }

  inject('storyblok', storyblok)
  context.$storyblok = storyblok
}