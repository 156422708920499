export const state = () => ({
  jobListings: null
});

export const mutations = {
  setJobs(state, obj) {
    state.jobListings = obj;
  }
};

export const actions = {
  async searchJobs({ commit }, searchQuery) {
    const jobs = await this.$hirebridge.getJobs(
      searchQuery,
      this.$constants.JOBS_RESULTS_PER_PAGE
    );

    commit("setJobs", jobs);
  },
  async searchSingleJob({ commit }, jobId) {
    const job = await this.$hirebridge?.getJobs({ jid: jobId });

    return job;
  }
};
