export default (context, inject) => {
  const submit = {
    async send(form, fields, replyto, subject = null, includeDate = true) {
      let config = {
        method: 'post',
        url: `${process.env.API_URL_LOCAL || window.location.origin}/submit`,
        data: {
          form,
          fields,
          replyto,
          subject,
          includeDate
        }
      }
      if(process.env.REQUIRE_VERIFY == 'true') {
        config.headers = {
          verify: await context.$recaptcha.execute('login')
        }
      }

      context.store.commit('setErrors', [])
      context.store.commit('setLoading', true)

      const submission = await context.$axios(config)
        .then(res => {
          return 'success'
        })
        .catch(err => {
          return err.response.data
        })

      context.store.commit('setLoading', false)
      if(submission == 'success') {
        return true
      } else {
        context.store.commit('setErrors', [submission])
        return false
      }
    }
  }

  inject('submit', submit)
  context.$submit = submit
}